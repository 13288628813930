/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
// import Link from 'gatsby-link'
import { css, jsx } from '@emotion/core'

// import { routes } from '../utils'
import {
  Page,
  H1,
  Hr,
  // GatsbyLink,
  Box,
  Flex,
  // FeatherIcon,
  Text,
} from '../elements'
import { SEO, HeadingStaticPage, SectionBoardgames } from '../components'
import { breakLongStrings, addInlineLinks } from '../utils'

const ProtozonePage = ({
  data: {
    boardgames: { nodes: boardgames = [] },
  },
}) => (
  <Page>
    {({ t }) => {
      const str = breakLongStrings({ string: t('protozoneText'), tag: 'p' })
      const strWithLinks = addInlineLinks(str)

      return (
        <React.Fragment>
          <SEO
            {...{
              url: `/protozone/`,
              title: t('protozone'),
              // description: true,
            }}
          />
          <HeadingStaticPage
            {...{
              // icon: 'map-pin',
              bg: 'protozone',
              color: 'textAlt',
              title: t('protozone'),
            }}
          />
          <Box.container>
            <Text
              {...{
                as: 'p',
                color: 'text',
                className: 'break show-links',

                dangerouslySetInnerHTML: { __html: strWithLinks },
              }}
            />
          </Box.container>

          <SectionBoardgames
            {...{ boardgames, t, headingPaperSection: { bg: 'protozone' } }}
          />
        </React.Fragment>
      )
    }}
  </Page>
)

export const pageQuery = graphql`
  query Protos {
    boardgames: allBoardgame(
      filter: { status: { eq: "published" }, proto: { eq: true } }
      sort: { fields: [name___fr] }
    ) {
      nodes {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        proto
        duration
        facebook
        featuredImage {
          url
          type
          thumbnails {
            small {
              height
              url
              width
            }
            large {
              height
              width
              url
            }
            full {
              height
              url
              width
            }
          }
        }
        accessibility
        age
        audience
        maxPlayers
        minPlayers
        pitch {
          en
          fr
          nl
        }
        relativePath
      }
    }
  }
`

export default ProtozonePage
